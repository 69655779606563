<template>
    <div>
        <Editor url="/audit/convenzione/" :fields="fields" :filters="filters"
                icon="mdi-handshake-outline"
                testo-list="Lista convenzioni"
                testo-insert="Nuova convenzione"
                testo-update="Modifica convenzione"
                testo-delete="Vuoi cancellare la convenzione?"
                :no-insert="!$auth('amministratore')"
                :no-update="!$auth('amministratore')"
                :no-delete="!$auth('amministratore')"
                :globalActions="globalActions"
                @globalAction="globalAction">
            <template #form="{instance, errors}">
                <v-text-field v-model="instance.codice" label="Codice"></v-text-field>
                <v-text-field v-model="instance.nome" label="Nome"></v-text-field>
                <v-select :items="tipi" :error-messages="errors.tipo" v-model="instance.tipo"
                          label="Tipo"></v-select>
                <v-select :items="tipi_attivita" :error-messages="errors.tipo_attivita" v-model="instance.tipo_attivita"
                          label="Tipo Attivita"></v-select>
                <v-select :items="stato" :error-messages="errors.stato" v-model="instance.stato"
                          label="Stato"></v-select>
                <Relation v-model="instance.checklist" url="/backend/autocomplete/checklist"
                          label="Checklist"></Relation>
                <v-text-field type="number" v-model="instance.gg_previsti" label="N° Giorni Previsti"></v-text-field>
                <v-text-field type="number" v-model="instance.gg_consuntivati"
                              label="N° Giorni Consuntivati"></v-text-field>

                <v-text-field type="number" v-model="instance.costo_giornata" label="Costo Giornata"></v-text-field>
                <v-text-field type="number" v-model="instance.costo_giornata_previsto"
                              label="Costo Giornata Previsto"></v-text-field>

                <v-text-field type="number" v-model="instance.n_ordinativi_previsti"
                              label="N° Ordinativi Previsti"></v-text-field>
                <v-text-field type="number" v-model="instance.n_ordini_consuntivati"
                              label="N° Ordinativi Consuntivati"></v-text-field>

                <v-text-field type="number" v-model="instance.costo_totale_peventivato"
                              label="Costo Totale Preventivato"></v-text-field>
                <v-select :items="costi" :error-messages="errors.costi" v-model="instance.costi"
                          label="Costi a Carico"></v-select>
                <v-text-field type="number" v-model="instance.nr_ncg"
                              label="Non Conformità Gravi"></v-text-field>

                <v-text-field type="number" v-model="instance.nr_ncl"
                              label="Non Conformità Lievi"></v-text-field>
                <v-text-field type="number" v-model="instance.nr_fatture_nncoformi"
                              label="Numero Fatture Non Conformi"></v-text-field>
                <DatePicker label="Data completamento" v-model="instance.data_completamento"></DatePicker>
                <DatePicker label="Data affidamento" v-model="instance.data_affidamento"></DatePicker>

            </template>
            <template #item.chiusa="{ item }">
                <Boolean v-model="item.chiusa"></Boolean>
            </template>
            <template #actions="{item}">
                <router-link :to="{name:'ordinativo_fatture', params: {id: item.id}}"
                             v-if="$auth(['amministratore', 'consip', 'ispettore', 'validatore'])">
                    <v-btn class="primary mr-2" small>Fatture</v-btn>
                </router-link>
                <router-link :to="{name:'ordinativi', params: {id: item.id, tipo: 'ispezioni'}}"
                             v-if="$auth(['amministratore', 'consip', 'ispettore', 'validatore'])">
                    <v-btn class="primary mr-2" small>Ispezioni</v-btn>
                </router-link>
                <router-link v-if="$auth(['amministratore', 'pianificatore','ispettore'])"
                             :to="{name:'ordinativi', params: {id: item.id, tipo: 'pianificazione'}}">
                    <v-btn class="primary mr-2" small>Pianificazione</v-btn>
                </router-link>
                <router-link :to="{name:'cruscotto', params: {id: item.id}}"
                             v-if="$auth(['amministratore', 'consip'])">
                    <v-btn class="primary mr-2" small>Cruscotto</v-btn>
                </router-link>
                <router-link :to="{name:'convenzione_allegati', params: {id: item.id}}">
                    <v-btn v-if="$auth(['amministratore', 'consip'])" class="primary mr-2" small>Reportistica
                        Convenzione
                    </v-btn>
                </router-link>
            </template>
        </Editor>
    </div>
</template>


<script>
import Editor from "@/components/Editor";
import Relation from "@/components/Relation";
import Boolean from "@/components/Boolean";
import DatePicker from "@/components/DatePicker";
import {mapState} from "vuex";

export default {
    data: () => ({
        fields: [
            {text: 'Codice', value: 'codice'},
            {text: 'Nome', value: 'nome'},
            {text: 'Verifica presso', value: 'tipo'},
            {text: 'Tipo Attività', value: 'tipo_attivita'},
            {text: 'Stato', value: 'stato'},
        ],
        globalActions: [
            {key: 'export', label: 'Export Rapporto', auth: ['amministratore', 'consip', 'ispettore']},
        ],
    }),
    computed: {
        ...mapState(['dataset']),
        filters() {
            return [
                {field: 'codice', label: 'Codice'},
                {field: 'id', label: 'Convenzione', type: 'ajax', url: '/backend/autocomplete/convenzione'},
                {field: 'tipo', label: 'Verifica presso', options: this.tipi},
                {field: 'tipo_attivita', label: 'Tipo Attività', options: this.tipi_attivita},
            ]
        },
        tipi_attivita() {
            return this.dataset['convenzione_tipo_attivita'];
        },
        tipi() {
            return this.dataset['convenzione_tipo'];
        },
        stato() {
            return this.dataset['convenzione_stato'];
        },
        costi() {
            return this.dataset['convenzione_costi'];
        },
    },
    methods: {
        async globalAction() {
            await this.$http.downloadFile(`/audit/convenzione/download_rapporto_excel/`);
        }
    },
    components: {
        Editor,
        Relation,
        Boolean,
        DatePicker
    },
}
</script>